<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="taskTable"
      title="관련업무 목록"
      :columns="popupParam.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      selection="multiple"
      rowKey="documentId"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="선택" icon="check" @btnClicked="select" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'task-relation-docu',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          mocRelatedTaskCd: '',
          columns: [],
        }
      },
    }
  },
  data() {
    return {
      grid: {
        data: [],
        height: '650px'
      },
      searchParam: {
        plantCd: null,
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      switch(this.popupParam.mocRelatedTaskCd) {
        case 'RT00000001': // 공정안전자료 업데이트
          this.listUrl = selectConfig.sop.moc.relationWorkDocu.case1.list.url
          break;
        case 'RT00000005': // 위험성평가 결과
          this.listUrl = selectConfig.sop.moc.relationWorkDocu.case2.list.url
          break;
        case 'RT00000010': // 교육 결과
          this.listUrl = selectConfig.sop.moc.relationWorkDocu.case3.list.url
          break;
        case 'RT00000015': // 가동전 점검 결과
          this.listUrl = selectConfig.sop.moc.relationWorkDocu.case4.list.url
          break;
        case 'RT00000020': // 안전작업 허가서
          this.listUrl = selectConfig.sop.moc.relationWorkDocu.case5.list.url
          break;
        default:
          break;
      }
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    select() {
      let selectData = this.$refs['taskTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요', // 사용자를 먼저 지정하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
  }
};
</script>
